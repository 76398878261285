// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Small Saucepan" location={location}>
    <SEO title="Winching App" />
    <h1>Winching App</h1>
    <p>
      The Winching app is an easy to use app for iPhone that helps you calculate loads
      and stresses on your winch and anchor points using various configurations.
    </p>
    <p>
      Note that the figures provided come without any warranty, and the safety
      of your winching operation is solely your responsibility.
    </p>
    <p>
    <Link style={{ boxShadow: `none` }} to="./privacy">
          Privacy policy
        </Link>.
    </p>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
